.snackbar {
  border-radius: 72px;
}
.alert {
  width: inherit;
  background: 0% 0% no-repeat padding-box !important;
  box-shadow: 3px 3px 6px #4e111067 !important;
  border-radius: 72px !important;
  opacity: 1 !important;
}
.snackbarCloseIcon {
  cursor: pointer;
}
.message {
  padding: 0 50px;
  font-family: Lato-Regular;
  font-size: 16px;
  line-height: 19px;
  word-break: break-all;
  color: #ffffff;
}
.form-wrapper {
    width: 90%;
    max-width: 700px;
    margin-top: 20px;
  }

  .box {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
  }
  
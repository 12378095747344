.input-wrapper {
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
}
.input-wrapper input {
  width: 100%;
  height: 100%;
  padding: 0 16px;
  border: 0;
  background-color: inherit;
  font-size: initial;
  color: initial;
}

.input-wrapper input::placeholder {
  opacity: 0.7;
}

.input-wrapper input:focus {
  outline: none;
}

.input-wrapper textarea {
  width: 100%;
  height: 100%;
  padding: 0 16px;
  border: 0;
  background-color: inherit;
  font-size: initial;
  color: initial;
}

.input-wrapper textarea::placeholder {
  opacity: 0.7;
}

.input-wrapper textarea:focus {
  outline: none;
}

.custom-input-wrapper {
  width: 100%;
  height: 100%;
  padding: 0 16px;
  border: 0;
  background-color: inherit;
  font-size: initial;
  color: initial;
  border: 1px solid rgb(208, 208, 208);
  border-radius: 6px;
}

.MuiInput-underline:before {
  border-bottom: 0 !important;
}

.Mui-focused {
  border-bottom: 0 !important;
}

.MuiInput-underline:hover {
  border-bottom: 0 !important;
}
.sliderRightBox {
    width: 65%;

    @media (max-width: 600px) {
        width: 100%;
    }
}

.sliderRightContainer {
    flex-direction: row;
    @media (max-width: 600px) {
        flex-direction: column;
        gap: 10px;
    }
}
@tailwind base;
@tailwind components;
@tailwind utilities;

     .scroll-bar-style::-webkit-scrollbar {
        height: 8px;
         background-color: rgba(51, 0, 255, 0);
      }
      
      .scroll-bar-style::-webkit-scrollbar-track {
         box-shadow: inset 0 0 5px grey; 
         border-radius: 10px;
      }
      
      .scroll-bar-style::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        border-radius:4px;
      }

      input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}
.slide{
}
.slide .single-slide {
    width: 100%;
    height: 600px;

    @media (max-width: 600px) {
        height: 400px;
    }
}
.slide .single-slide .img-wrapper{
    height: inherit;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background-color: inherit;
    object-fit: contain;
    z-index: 1;
    position: relative;
}

.overlay-wrapper{
    height: inherit;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    overflow: hidden;
    background-color: inherit;
    object-fit: contain;
    z-index: 9;
    position: absolute;
    background-color: rgba(0,0,0,0.4);
}

.slide .single-slide img{
    width: auto;
    height: inherit;
    max-width: unset;
}

.slide  button{
    display: none!important;
}

.slide .slick-dots{
    bottom: 16px;
}
.slide .slick-dots .dots-wrapper{
    margin: 0;
    padding: 0;
}
.slide .slick-dots li{
    margin: 0 4px;
    padding: 0;
    width: fit-content;
    height: fit-content;
    
}
.slide .slick-dots .custom-dot{
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    width: 12px;
    height: 12px;
    border-radius: 100%

}

.slide .slick-dots .slick-active .custom-dot{
    outline: 2px solid #162E9A;
}


.chat-message-list {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .cs-message-list__scroll-wrapper {
        height: auto;
    }
}


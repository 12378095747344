.myPostBox{
    display: flex;
    box-shadow: 4px 6px 5px -6px rgba(0,0,0,0.75);
-webkit-box-shadow: 4px 6px 5px -6px rgba(0,0,0,0.75);
-moz-box-shadow: 4px 6px 5px -6px rgba(0,0,0,0.75);

    @media (max-width: 640px) {
        flex-direction: column;
    }
}

.myPostImage {
    @media (max-width: 640px) {
        width: 100%;
    }
}

.myPostTitle {
    @media (max-width: 640px) {
        margin-top: 1rem;
    }
}
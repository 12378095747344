.image {
  &:hover {
    .backDrop {
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 9;
      background-color: rgba(0,0,0,0.7);
      position: absolute;
      top: 0;
    }
  }
}

.backDrop {
  display: none;
}

.input[type=number] {
  -moz-appearance: textfield;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none; 
}